/* eslint-disable complexity */
/* eslint-disable quotes */
/* eslint-disable no-undef */
'use strict';

const { secondaryBtnsHandlers, showMainFormBtnHandler, showMoreTermsHandler, handleCloseSection, handleWinnersDisplay, resetForm } = require('./modules/buttonsHandlers');
const { isValidForm } = require('./modules/validations');
const { sendRequest } = require('./modules/orangePlayApi');
const { handleWinnerForm } = require('./modules/winnerFormHandler');

document.addEventListener('DOMContentLoaded', function () {
	showMainFormBtnHandler();
	secondaryBtnsHandlers();
	showMoreTermsHandler();
	handleCloseSection();
	handleForm('.section-form--main', 'play', '.main-error-container');
	handleForm('.section-form--check-prizes', 'play', '.main-error-container');
	handleWinnerForm();
	handleWinnersDisplay();
});

function handleForm(formSelector, param, errorContainer) {
	const form = document.querySelector(formSelector);
	const submitFormBtn = form.querySelector('.btn--submit');

	submitFormBtn.addEventListener('click', function () {

		if (!isValidForm(form)) {
			return;
		}

		let payload = {
			"code": form.querySelector('.form-control__raffle-code').value.toUpperCase(),
			"phone": form.querySelector('.form-control__phone-input').value
		};

		sendRequest(param, payload, form)
			.then(function (response) {
				console.log(response);
				if (response.error && response.message === 'Already played' && response.status === 'winner' && form.classList.contains('section-form--main')) {
					handleError(form, errorContainer, response.status);
					return;
				}

				if (response.error && response.message === 'Already played' && response.status === 'winner' && form.classList.contains('section-form--check-prizes')) {
					handleSuccess(form, response.prize);
					return;
				}

				if (response.error && response.message === 'No transaction found') {
					handleInvalidCode(form);
					return;
				}

				if (response.error) {
					handleError(form, errorContainer, response.status);
					return;
				}

				if (response.success && response.status === 'No prizes have remained') {
					handleCampaignOver(form);
					return;
				}

				if (!response.error && response.status === 'winner') {
					if (!form.classList.contains('section-form--check-prizes')) {
						addSpinWheelAnimation();
					}
					handleSuccess(form, response.prize);
					return;
				}
			});
	});
}

function addSpinWheelAnimation() {
	let divElement = document.createElement('div');
	let img = document.createElement('img');

	img.src = '../images/WheelSpin_V2.gif';
	divElement.appendChild(img);
	divElement.classList.add('wheel-spin-container');

	document.getElementById('omni-container').appendChild(divElement);

	setTimeout(function () {
		divElement.remove();
	}, 5000);
}

function handleError(mainForm, errorContainer, winnerStatus) {

	if (winnerStatus === 'winner' && mainForm.querySelector('.message-already-winner')) {
		mainForm.querySelector('.message-already-winner').classList.remove('d-none');
		return;
	}
	mainForm.querySelector(errorContainer).classList.remove('d-none');
	mainForm.querySelector(errorContainer).textContent = 'Din păcate, datele introduse nu sunt corecte. Te rugăm să le verifici din nou.';
}

function handleSuccess(mainForm, prize) {
	mainForm.classList.remove('active-section');

	const winnersForm = document.querySelector('.section-form--winner');

	if (mainForm.classList.contains('section-form--check-prizes')) {
		winnersForm.querySelector('.winner-form-only').classList.add('d-none');
		winnersForm.querySelector('.winner-form-only').classList.remove('d-block');
	}

	winnersForm.classList.add('active-section');

	insertPrizeDetails(winnersForm, prize);
	resetForm(mainForm);
}

function insertPrizeDetails(form, prize) {
	let voucherValue;

	if (prize.voucher_type.toLowerCase().indexOf('magazinul online') !== -1 || prize.voucher_type.toLowerCase().indexOf('oro') !== -1) {
		voucherValue = prize.voucher_value + ' €';
	} else if (prize.voucher_type.toLowerCase().indexOf('decathlon') !== -1) {
		voucherValue = prize.voucher_value + ' lei';
	} else if (prize.voucher_type.toLowerCase().indexOf('carrefour') !== -1) {
		voucherValue = prize.voucher_value + ' lei';
	}

	form.querySelector('.form__anouncement .voucher-value').textContent = voucherValue;
	form.querySelector('.form__anouncement .voucher-type').textContent = prize.voucher_type;
	form.querySelector('#voucherCode').value = prize.voucher_code;

	if (prize.voucher_pin !== null) {
		const voucherPinFormGroup = form.querySelector('.form-group--voucher-pin');

		voucherPinFormGroup.classList.remove('d-none');
		voucherPinFormGroup.querySelector('#voucherPin').value = prize ? prize.voucher_pin : 'VOUCHER PIN';
	}
}

function handleCampaignOver(form) {
	form.classList.remove('active-section');
	document.querySelector('.section-campaign-over').classList.add('active-section');
}

function handleInvalidCode(form) {
	form.querySelector('.message-already-played').classList.remove('d-none');
}
