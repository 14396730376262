/* eslint-disable quotes */
/* eslint-disable complexity */
'use strict';

const { isValidForm } = require('./validations.js');
const { sendRequest } = require('./orangePlayApi.js');
const { resetForm } = require('./buttonsHandlers.js');

module.exports.handleWinnerForm = function () {
	const winnerForm = document.querySelector('.section-form--winner');
	const submitBtn = winnerForm.querySelector('button.btn-submit');
	const voucherCodeInput = winnerForm.querySelector('#voucherCode');
	const voucherPinInput = winnerForm.querySelector('#voucherPin');
	const emailInput = winnerForm.querySelector('#email');

	submitBtn.addEventListener('click', function () {

		if (!isValidForm(winnerForm)) {
			return;
		}

		let payload = {
			"code": voucherCodeInput.value,
			"email": emailInput.value
		};

		if (voucherPinInput.value) {
			payload.pin = voucherPinInput.value.toUpperCase();
		}
		sendRequest('notify', payload, winnerForm)
			.then(function (response) {

				if (response.error && response.message === 'Invalid code') {
					handleError('Nu s-a putut trimite email');
					return;
				}

				if (response.error) {
					handleError('Din păcate, datele introduse nu sunt corecte. Te rugăm să le verifici din nou.');
					return;
				}

				if (response.success) {
					handleSuccess();
				}
			});
	});

	function handleError(message) {
		const errorContainer = winnerForm.querySelector('.main-error-container');

		errorContainer.classList.remove('d-none');
		errorContainer.textContent = message;
	}

	function handleSuccess() {
		winnerForm.classList.add('text-center');
		winnerForm.querySelector('.form__confetti').classList.add('d-none');
		winnerForm.querySelector('.form__confetti').classList.remove('d-flex');
		winnerForm.querySelector('.form__info').classList.add('d-none');
		winnerForm.querySelector('.winner-form').classList.add('d-none');
		winnerForm.querySelector('.btn-submit').classList.add('d-none');
		winnerForm.querySelector('a.btn-primary').classList.remove('d-none');
		winnerForm.querySelector('.succes-message-container').classList.remove('d-none');

		resetForm(winnerForm);
	}
};

